<template>
  <div
    class="w-full rounded tile"
    :style="{
      borderRadius: borderRadius,
      boxShadow: boxShadow,
      background: background,
      border: border,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "Tile",

  props: {
    borderRadius: {
      type: [String, Number],
      default: "5px",
    },

    background: {
      type: String,
      default: "#231d3a",
    },
    boxShadow: {
      type: String,
      default: "0px 0px 20px rgb(0 0 0 / 5%)",
    },
    border: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import "../styles/globals";
@import "../styles/colors";

.tile {
  border: $border-primary;
  background-color: color(blue, base);
}
</style>
